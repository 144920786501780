<template>
  <div>
    <ContentHeader title="Laporan Farmasi" subTitle="Laporan Penjualan Obat" url="laporan-farmasi" />
    <div class="content-header">
      <div class="container-fluid">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Data Laporan</h3>
          </div>
          <div class="card-body" style="display: block;">
            <div class="row align-items-center mt-2">
              <div class="col-lg-2">
                <span>Periode Awal</span>
                <input type="date" class="form-control" v-model="periode_awal">
              </div>
              <div class="col-lg-2">
                <span>Periode Akhir</span>
                <input type="date" class="form-control" v-model="periode_akhir">
              </div>
              <div class="col-lg-1 pt-4">
                <button class="btn btn-primary" @click="getDataResult">Filter</button>
              </div>
              <div class="col-lg-2"></div>
              <div class="col-lg-5">
                <span>Supplier</span>
                <multiselect 
                  v-model="supplier_select_value"
                  deselect-label="Can't remove this value"
                  track-by="nama_lengkap"
                  label="nama_lengkap"
                  placeholder=""
                  :custom-label="customSelectSupplier"
                  :options="supplier"
                  :loading="loading_supplier"
                  :allow-empty="false"
                  @update:model-value="getDataResult"
                >
                </multiselect>
              </div>
            </div>
            <table class="table table-bordered table-hover mt-4">
              <thead>
                <tr>
                  <th scope="col" class="text-sm">JATUH TEMPO</th>
                  <th scope="col" class="text-sm">FAKTUR</th>
                  <th scope="col" class="text-sm">SUPPLIER</th>
                  <th scope="col" class="text-sm">PRODUK</th>
                  <th scope="col" class="text-sm">JUMLAH</th>
                  <th scope="col" class="text-sm">HARGA</th>
                  <th scope="col" class="text-sm">BRUTO</th>
                  <th scope="col" class="text-sm">DISC</th>
                  <th scope="col" class="text-sm">NETTO+PPN+ONGKIR</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in data_result" :key="row.id">
                  <td class="text-sm p-2" scope="row">
                    <table class="table-borderless">
                      <tr>
                        <td class="text-sm p-2" scope="row" >{{ row.jatuh_tempo }}</td>
                      </tr>
                    </table>
                  </td>
                  <td class="text-sm p-2" scope="row">
                    <table class="table-borderless">
                      <tr>
                        <td class="text-sm p-2" scope="row" >{{ row.nomor_faktur }}</td>
                      </tr>
                    </table>
                  </td>
                  <td class="text-sm p-2" scope="row">
                    <table class="table-borderless">
                      <tr>
                        <td class="text-sm p-2" scope="row" >{{ row.nama_penjual }}</td>
                      </tr>
                    </table>
                  </td>
                  <td class="text-sm p-2" scope="row" >
                    <table class="table-borderless">
                      <tr v-for="(value, index) in row.pembelian_detail" :key="index">
                        <td class="text-sm p-2" scope="row" >{{ value.produk.nama }}</td>
                      </tr>
                    </table>
                  </td>
                  <td class="text-sm p-2" scope="row" >
                    <table class="table-borderless">
                      <tr v-for="(value, index) in row.pembelian_detail" :key="index">
                        <td class="text-sm p-2" scope="row" >{{ value.jumlah }}</td>
                      </tr>
                    </table>
                  </td>
                  <td class="text-sm p-2" scope="row" >
                    <table class="table-borderless">
                      <tr v-for="(value, index) in row.pembelian_detail" :key="index">
                        <td class="text-sm p-2" scope="row" >{{ format_nominal(value.harga_beli) }}</td>
                      </tr>
                    </table>
                  </td>
                  <td class="text-sm p-2" scope="row">
                    <table class="table-borderless">
                      <tr>
                        <td class="text-sm p-2" scope="row" >{{ format_nominal(row.total) }}</td>
                      </tr>
                    </table>
                  </td>
                  <td class="text-sm p-2" scope="row">
                    <table class="table-borderless">
                      <tr>
                        <td class="text-sm p-2" scope="row" >{{ format_nominal(row.diskon) }}</td>
                      </tr>
                    </table>
                  </td>
                  <td class="text-sm p-2" scope="row">
                    <table class="table-borderless">
                      <tr>
                        <td class="text-sm p-2" scope="row" >{{ format_nominal(row.total) }}</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <p class="text-center mt-2" v-if="memuat_data">Memuat Data...</p>
            <p class="text-center mt-2">{{ cek_data }}</p>
          </div>
          <!-- /.card-body -->
          <div class="card-footer">
          </div>
          <!-- /.card-footer-->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader2'
import Multiselect from '@suadelabs/vue3-multiselect'

import { ref, computed, onMounted } from 'vue'
import store from '@/store'
import axios from 'axios'
import format_nominal from '@/format_nominal'

const user = computed(() => store.getters['auth/user'])
const data_result = ref([])
const cek_data = ref('')
const memuat_data = ref(false)
const periode_awal = ref('')
const periode_akhir = ref('')
const total = ref('')

const getDataResult = async () => {
  data_result.value = []
  total.value = ''
  memuat_data.value = true
  cek_data.value = ''
  let supplier_id = ''
  let tanggal_awal = ''
  let tanggal_akhir = ''

  if (periode_awal.value == '' && periode_akhir.value == '') {
    tanggal_awal = 'emptyParameter'
    tanggal_akhir = 'emptyParameter'
  } else {
    tanggal_awal = periode_awal.value
    tanggal_akhir = periode_akhir.value
  }

  if (supplier_select_value.value == '') {
    supplier_id = 'emptyParameter'
  } else {
    supplier_id = supplier_select_value.value.id
  }

  let { data } = await axios.get(`api/laporan/pembelianObat/${user.value.cabang_id}/${tanggal_awal}/${tanggal_akhir}/${supplier_id}`)
  console.log(data)
  if (data == 'kosong') {
    cek_data.value = 'Data Masih Kosong'
    memuat_data.value = false
    data_result.value = []
  } else {
    memuat_data.value = false
    cek_data.value = ''
    data_result.value = data.data
    total.value = data.total
  }
}

const supplier = ref([])
const supplier_select_value = ref('')
const loading_supplier = ref(true)

const customSelectSupplier = ({ nama }) => {
  return `${nama}`
}

const getSupplier = async () => {
  loading_supplier.value = true
  let { data } = await axios.get(`api/supplier/get/${user.value.cabang_id}`)

  if (data != 'kosong') {
    supplier.value = data
    loading_supplier.value = false
  } else {
    supplier.value = []
    loading_supplier.value = false
  }
}

onMounted(() => {
  getSupplier()
  getDataResult()
})
</script>